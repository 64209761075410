<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { required } from "vuelidate/lib/validators";

import {
	projectMethods,
    analyticsMethods,
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "RFM",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "RFM",
      items: [],
      showModalHost: false,
      showModalProducts:false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      color: "#007bff",
      bgColor: "#ffffff",
      height: 128,
      width: 128,
      project:null,
      submitted:false,
      projects:[],
      emptyList:false ,
      reports:[],
      segments:[],
      segment:null,
      csvUrl: null,
      htmlUrl:null,
      showSegmentIdea:false,
      ideaContent:"",
      months:[
        this.$t('common.months.january'),
        this.$t('common.months.february'),
        this.$t('common.months.march'),
        this.$t('common.months.april'),
        this.$t('common.months.may'),
        this.$t('common.months.june'),
        this.$t('common.months.july'),
        this.$t('common.months.august'),
        this.$t('common.months.september'),
        this.$t('common.months.october'),
        this.$t('common.months.november'),
        this.$t('common.months.december')
      ]
    }
  },
  validations: {
    project: {
      name: {
        required,
      }
    },
  },
  mounted() {
    
    this.loadProjectRFM(localStorage.getItem("current_project"));
  },
  created() {
    
  },
  methods: {
    
    ...projectMethods,
    ...analyticsMethods,

    onProjectSelected(){
      if(this.project && this.project._id){
        this.loadProjectRFM(this.project._id)
      }
    },

    loadProjects(){
      let loader = this.$loading.show();
      this.getProjects().then((projects)=>{
        if(projects&& projects.data){
          this.projects= projects.data;
      }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('projects.get_projects_error'), title: this.$t('projects.title') });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },


    loadProjectRFM(id){
      
        let loader = this.$loading.show();
        const params = `project=${id}`
      
        this.getRFMReport(params).then((data)=>{
          if(!data || !data.data || data.data.length == 0){
                this.emptyList = true;
                return;
            }

            const  reportData = data.data.filter(r=> r.report== 'customer-segmentation-RFM-summary');

            if(!reportData && reportData.length == 0){
                this.emptyList = true;
                return;
            }

            const reports = data.data;

            fetch(reportData[0].uri)
                .then(response => {
                    return response.json()
                })
                .then((data)=>{
                    this.segments = data;
                    this.csvUrl = reports.filter(r=> r.report== 'customer-segmentation-RFM-csv').length > 0 ? reports.filter(r=> r.report== 'customer-segmentation-RFM-csv')[0].uri : null;
                    this.htmlUrl = reports.filter(r=> r.report== 'customer-segmentation-RFM-html').length > 0 ? reports.filter(r=> r.report== 'customer-segmentation-RFM-html')[0].uri : null;
                    this.emptyList = false;
                    
                }).catch(()=>{
                    this.$notify({ type: 'error', text: this.$t('segmentation.rfm_report_error'), title: this.$t('segmentation.rfm') });
                }).finally(()=>{
                    loader.hide();
                })
        }).catch(()=>{
            this.$notify({ type: 'error', text: this.$t('segmentation.rfm_report_error'), title: this.$t('segmentation.rfm') });
        }).finally(()=>{
            loader.hide();
        });

    },
    
    downloadOptionSeleted(option){
        if(option == 'csv' && this.csvUrl){
            window.open(this.csvUrl);
        }else if (option == 'html' && this.htmlUrl){
            window.open(this.htmlUrl);
        }
    },
    
    onDetailsClicked(segment){
        
        this.segment = segment;

        let loader = this.$loading.show();
        const params = `segmentName=${this.getSegmentName(segment)}&segmentCount=${segment.count}&project=${localStorage.getItem("current_project")}`;

        this.getRFMReportText(params).then((data)=>{
          
          this.ideaContent = data.content;
          this.showSegmentIdea = true;
          
        }).catch((ex)=>{
          // eslint-disable-next-line no-console
          console.log(ex)
        }).finally(()=>{
          loader.hide();
        })
    },

    getSegmentName(segment){
        if(this.$i18n.locale === 'es'){
            return segment.etiqueta || segment.Segment
        }else{
            return segment.label || segment.Segment
        }
    }

    
},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('segmentation.rfm')" :items="items" />
    <div class="row">
      
        <div class="row mb-2">
            <div class="col-sm-4">
                
            </div>
            <div class="col-sm-8">
                <div class="text-sm-end">

                    <b-dropdown variant="primary">
                        <template v-slot:button-content>
                            {{$t('segmentation.rfm_download')}}
                            <i class="mdi mdi mdi-download"></i>
                        </template>
                        <b-dropdown-item v-on:click="downloadOptionSeleted('csv')">{{$t('segmentation.rfm_download_csv')}}</b-dropdown-item>
                        <b-dropdown-item v-on:click="downloadOptionSeleted('html')">{{$t('segmentation.rfm_download_html')}}</b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
    </div>
      <div class="col-lg-12">
        <div>
          <div class="table-responsive">
            <table class="table project-list-table table-nowrap table-centered table-borderless align-middle">
              <thead>
                <tr>
                  <th scope="col">{{$t('segmentation.rfm_segment')}}</th>
                  <th scope="col">{{$t('segmentation.rfm_count')}}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="segment in segments" :key="segment.segment">
                    <td>
                        {{ getSegmentName(segment) }}
                    </td>
                    <td>
                        {{ segment.count }}
                    </td>
                    <td>
                        <i class= 'bx bx-bulb interact' v-on:click="onDetailsClicked(segment)" />
                    </td>
                </tr>   
              </tbody>
            </table>
            <p class="text-center" v-if="emptyList">{{$t('segmentation.rfm_empty_segments')}}</p>
          </div>
        </div>
    </div>
</div>
<b-modal
      v-model="showSegmentIdea"
      :title= "segment?.Segment"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer>
      <div>
        <span v-html="ideaContent?.replaceAll('.','. <br>').replaceAll(':',': <br>')"></span>
      </div>
    </b-modal>
  </Layout>
</template>

<style scoped>
.interact{
	cursor: pointer;
  }
</style>


